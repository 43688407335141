@media (max-width: 767px) {
    .optionalPara {
        display: none !important;
    }
}

.centerDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    scroll-margin-top: 100px;
    margin-top: 3rem;
    margin-bottom: 4rem;
}

.imageDiv {
    height: 23rem;
    width: 15rem;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    background: #18363f;
    background-color: #18363f;
}

.sectionh2 {
    font-size: 32px !important;
}

.imageDiv::after {
    content: "";
    border: 2px solid white;
    width: 14rem;
    height: 22rem;
    display: block;
    position: absolute;
    transform: translate(-37px, -37px);
    z-index: -1;
    opacity: 0.5
}

.glowingBorder {
    border: 2px solid transparent; /* Optional: adjust or remove if not needed */
    box-shadow: 0 0 25px 5px rgba(255, 255, 255, 0.5);
  }

.profileImage {
    height: 22.9rem;
    width: 18.1rem;
    background: #18363f;
}

@media (max-width: 767px) {
    .imageDiv {
        height: 15rem;
        width: 10rem;
    }

    .imageDiv::after {
        width: 9rem;
        height: 15rem;
        transform: translate(-25px, -25px);
    }

    .profileImage {
        height: 14.9rem;
        width: 11.1rem;
    }

}

@media (max-width: 500px) {
    .imageDiv {
        height: 10rem;
        width: 7rem;
    }

    .imageDiv::after {
        width: 6rem;
        height: 9rem;
        transform: translate(-20px, -20px);
    }
    
    .profileImage {
        height: 9.9rem;
        width: 7.1rem;
    }
}

@media (max-width: 400px) {
    .imageDiv {
        height: 8rem;
        width: 6rem;
    }

    .imageDiv::after {
        width: 5rem;
        height: 7rem;
        transform: translate(-20px, -18px);
    }

    .profileImage {
        height: 7.9rem;
        width: 5.1rem;
    }
}


.hideDiv {
    visibility: hidden;
}

.animateFromLeft {
    animation: slideFromLeft 0.5s ease forwards;
}

@keyframes slideFromLeft {
    0% {
        transform: translateX(-100%);
        visibility: hidden;
    }
    100% {
        transform: translateX(0);
        visibility: visible;
    }
}

.animateFromRight {
    animation: slideFromRight 1s ease forwards;
}

@keyframes slideFromRight {
    0% {
        transform: translateX(100%);
        visibility: hidden;
    }
    100% {
        transform: translateX(0);
        visibility: visible;
    }
}