.wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0px !important;
    justify-content: center;
  }
  
  .wrapper .icon {
    position: relative;
    background: #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .wrapper .icon2 {
    position: relative;
    background: #ffffff;
    border-radius: 1rem;
    padding: 15px;
    margin: 10px;
    width: 200px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .resumeButton {
    text-decoration: none;
  }
  
  .wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  
  .wrapper .icon:hover span,
  .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    color: white !important;
  }
  
  .wrapper .linkedin:hover,
  .wrapper .linkedin:hover .tooltip,
  .wrapper .linkedin:hover .tooltip::before {
    background: #0967C2;
    color: #ffffff;
  }
  
  .wrapper .github:hover,
  .wrapper .github:hover .tooltip,
  .wrapper .github:hover .tooltip::before {
    background: #333333;
    color: #ffffff;
  }

  .wrapper .gmail:hover,
  .wrapper .gmail:hover .tooltip,
  .wrapper .gmail:hover .tooltip::before {
    background: #C52220;
    color: #ffffff;
  }

  .wrapper .resume:hover,
  .wrapper .resume:hover .tooltip,
  .wrapper .resume:hover .tooltip::before {
    background: grey;
    color: #ffffff;
  }
  
  
      .meta-link {
        align-items: center;
        backdrop-filter: blur(3px);
        background-color: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        display: inline-flex;
        gap: 5px;
        left: 10px;
        padding: 10px 20px;
        position: fixed;
        text-decoration: none;
        transition: background-color 600ms, border-color 600ms;
        z-index: 10000;
      }
  
      .meta-link:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
  
      .meta-link>i,
      .meta-link>span {
        height: 20px;
        line-height: 20px;
      }
  
      .meta-link>span {
        color: white;
        font-family: "Rubik", sans-serif;
        transition: color 600ms;
      }