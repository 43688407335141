.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  /* add fadein animation */
  backface-visibility: hidden;
  animation: slideBounce 0.5s ease-in-out;
}

@keyframes scaleFadeIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideBounce {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  70% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.modalOverlay .opennow {
  opacity: 1 !important;
  transition: opacity 0.3s ease-in-out;
}

.modalContent {
  position: relative;
  width: 95vw;
  height: auto;
  background: #1C1E1C;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: 1px solid #ffffff;
  transform: translateY(32px);
}

.modalContent::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .modalContent {
    width: 95vw;
    height: auto;
  }
}

.projectTitle, .projectPeriod, .projectdescription {
  color: white;
  margin-bottom: 0.5rem;
}

.projectTitle {
  font-size: 1.5rem;
}

.projectPeriod, .projectdescription {
  font-size: 1rem;
  margin-bottom: 1.5%;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  background: none;
  color: white;
}

.viewLive {
  background-color: #ffffff;
  color: black;
  border: 1px solid #000000;
  padding: 0.25rem 0.5rem;
  font-size: 0.70rem;
  font-weight: bold;
  white-space: nowrap;
  border-radius: 1rem;
  margin-top: 1.5%;
  margin-left: 5px;
  margin-bottom: 1.5%;
  margin-right: 10%;
}

@media (min-width: 768px) {
  .viewLive {
    max-width: 15%;
    margin-top: 1.5%;
  }
}

@media (max-width: 767px) {
  .viewLive {
    max-width: 40%;
    margin-top: 1.5%;
  }
}

@media (max-width: 500px) {
  .viewLive {
    margin-top: 3.5%;
  }
}

.techContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
}

.techBadge {
  background-color: #ffffff;
  color: black;
  border: 1px solid #000000;
  padding: 0.25rem 0.5rem;
  font-size: 0.70rem;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 0.5rem;
  margin-bottom: 1.5%;
  border-radius: 1rem;
}