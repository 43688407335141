body {
    background-color: black;
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 19%, rgba(13,16,14,0.89 ) 50%, rgba(14,15,13,1) 95%);
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.skip-to-main-content-link {
    position: absolute;
    left: -9999px;
    z-index: 999;
    padding: 1em;
    background-color: black;
    color: white;
    opacity: 0;
  }
  .skip-to-main-content-link:focus {
    left: 10%;
    transform: translateX(-50%);
    opacity: 1;
    z-index: 1000;
  }

@media (max-width: 574px) {
    body {
        display: flex;
    }
}

::-webkit-scrollbar-button {
    display: block;
    height: 0px;
    border-radius: 0px;
    background-color: #313741;
}

::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 3px;
}

::-webkit-scrollbar-track {
    background-color: #313741;
}

::-webkit-scrollbar {
    width: 8px;
}

.disable-scrolling{
    height: 100%;
    overflow: hidden;
    background: black;
    position: fixed;
}