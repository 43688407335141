/* Background and padding for the skills section */
.skillsSection {
    padding-left: 10px;
    padding-right: 10px;
    scroll-margin-top: 100px;
}

/* Middle area styling */
.skillsAreaMiddle {
    padding: 20px 0;
    margin: 0 -11px;
    padding-left: 10px;
    padding-right: 10px;
}

/* Styles for the skills display area */
.skillst5 {
    position: relative; /* Ensures all child absolute elements are positioned relative to this */
    padding: 5px;
    background-color: #1C1E1C;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.category {
    border-radius: 10px; 
}

.sectionh2 {
    font-size: 32px !important;
}

.skills__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    opacity: 0.9;
    margin: 10px;
    transition: all 0.3s ease;
}

.skills__item img {
    height: 30px;
    margin-bottom: 20px;
}

.categoryBackground {
    /* background-color: #1C1E1C; */
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
}

/* Existing styles remain the same */

.skills__item {
    /* Add initial opacity and transition for smooth animation */
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateY(20px); /* Initial position */
}

.isVisible {
    /* Make the skill visible and slide up */
    opacity: 1;
    transform: translateY(0);
}

@keyframes slideInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.skills__item.slideIn {
    animation: slideInUp 0.5s ease-out forwards;
}
