.contactContainer {
    color: #fff;
    padding: 60px 0;
    scroll-margin-top: 70px;
}

.successMessage {
    font-size: 16px;
    color: #4CAF50;
    background-color: #f0fff0;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
}

.validationError {
    color: #ff0000; 
    font-size: 14px; 
    margin-top: 5px; 
}

.contactRow {
    margin-bottom: 40px;
}

.sectionTitle {
    margin-bottom: 50px;
}

.contactHeading {
    color: #fff;
    text-transform: uppercase;
    font-size: 2.5rem;
    margin-bottom: 0;
}

.contactInfo {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}

.infoCard {
    text-align: center;
    margin-bottom: 30px;
}

.infoIcon {
    font-size: 24px;
    color: #00d7bb;
    margin-bottom: 10px;
}

.contactForm {
    background-color: #222;
    padding: 40px;
    border-radius: 8px;
}

.formGroup {
    position: relative;
    margin-bottom: 45px;
}

.formControl {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #aaa;
    outline: none;
    color: #fff;
    padding: 10px 0;
    transition: border-color 0.3s;
    font-size: 18px;
}

.formControl:focus,
.formControl:not(:placeholder-shown) {
    border-bottom-color: #00d7bb;
}

.formLabel {
    position: absolute;
    top: 0;
    left: 0;
    color: #aaa;
    transition: top 0.3s, font-size 0.3s, color 0.3s;
    pointer-events: none;
    font-size: 18px;
}

.formControl:not(:placeholder-shown) + .formLabel,
.formControl:focus + .formLabel {
    top: -20px;
    font-size: 14px;
    color: #ffffff;
}

.formGroupTextarea .formLabel {
    top: 20px;
}

.submitBtn {
    background-color: #00d7bb;
    border: none;
    padding: 10px 30px;
    color: #fff;
    transition: background-color 0.3s;
    cursor: pointer;
}

.submitBtn:hover {
    background-color: #00d7bb;
}

@media (max-width: 768px) {
    .contactInfo {
        flex-direction: column;
    }

    .infoCard {
        margin-bottom: 20px;
    }
}

.contactEmailText {
    text-decoration: none;
    color: white;
}