.footerText {
    color: #fff;
    font-size: 80%;
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
    width: 100%;
}

.footerLink {
    color: #00d7bb;
    text-decoration: none;
    font-weight: bold;
}