/* Main styles for the navbar */
.setPadding {
    padding-left: 10px;
    padding-right: 4px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    width: 100%;
    background-color: transparent; /* Default transparent background */
    color: white; /* Initial text color */
}

/* Styling when the page is scrolled */
.scrolled {
    background-color: #1C1E1C; /* Light background on scroll */
    color: white; /* Dark text on scroll */
}

/* Ensure all links and toggler are visible against light background when scrolled */
.scrolled .customNavLink, .scrolled .navbarToggler {
    color: #FFFFFF !important;
}

/* Initial styles for navbar links */
.customNavLink {
    position: relative;
    color: white !important; /* Initial text color */
    margin: 8px 12px;
}

.customNavLink::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: white; /* Initial color for underline */
    transition: width 0.3s ease;
}

.customNavLink:hover::before {
    width: 100%;
    background-color: white; /* Color change on hover */
    transition-delay: 0.1s;
}

/* Navbar toggler specific styling */
.navbarToggler {
    background-color: white !important;
}

/* Responsive adjustments for navbar links */
@media (max-width: 992px) {
    .setPadding {
        position: fixed; /* Adjust position so it doesn't overlap content */
        background: transparent; /* Transparent background initially */
        opacity: 1; /* Full opacity */
    }

    .new_ul {
        background-color: #1C1E1C;
        color: white;
    }

    .scrolled .setPadding {
        background-color: white; /* White background when scrolled */
        opacity: 0.9; /* Slightly transparent for a soft effect */
    }

    .scrolled {
        background-color: #1C1E1C; /* Light background on scroll */
        color: #FFFFFF; /* Dark text on scroll */
    }

    .customNavLink {
        color: #FFFFFF !important; /* Dark text color when scrolled */
    }
    .headerBackground {
        /* background: linear-gradient(to right, black, white); Darker gradient background */
        border-bottom-left-radius: 10px;
    }
    
    .navbar-collapse.collapsing,
    .navbar-collapse.show {
        background-color: #ffffff !important; /* Background to ensure content is readable */
        margin-top: 0px;
        position: absolute; /* Position absolute to cover the content below */
        width: 100%; /* Full width to cover side spaces */
        border-radius: 0; /* Optional, depending on your design */
        color: #000;
    }

    .navbar-toggler {
        background-color: white !important; /* Ensure the toggler is visible */
    }
}

@media (max-width: 992px) {
    .resumeBtn {
        color: black !important; /* Dark text color */
    }
}
/* Resume button specific styles */
.resumeBtn {
    background: none; /* No initial background gradient */
    border-radius: 30px !important;
    color: white; /* Initial text color */
    margin-left: 20px;
    margin: 8px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; /* Ensure smooth color transition */
}

.resumeBtn:hover, .resumeBtn:focus {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.62)); /* Lighter gradient on hover */
    color: black; /* Text color change on hover for better visibility */
}

.scrolled .resumeBtn {
    color: black; /* Dark text on light background when scrolled */
    background: linear-gradient(to right, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.20)); /* Subtle black gradient background when scrolled */
}

.scrolled .resumeBtn:hover, .scrolled .resumeBtn:focus {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.30)); /* Darker gradient on hover when scrolled */
}

.navbar-collapse.collapse {
    display: block !important;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  
  .navbar-collapse.collapsing {
    height: auto !important;
    transition: height 0.35s ease;
  }
  
  .navbar-collapse.show {
    height: auto !important;
    overflow: auto;
  }