.projectsContainer {
    min-height: 100vh;
    color: #333; /* Adjust text color as needed */
    scroll-margin-top: 70px;
  }
  
  .cardsRow {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    padding-top: 2rem;
  }
  
  .card2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    max-height: 446px;
    max-width: 400px;
    color: #ffffff;
    border-radius: 18px;
  }

  .sectionh2 {
    font-size: 32px !important;
  }
  
  .cardImageContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: contents;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  
  .cardImage {
    width: 100%;
    height: 72%;
    display: block;
    transition: transform 0.3s ease-in-out;
    margin-top: 18px;
    border-radius: 18px;
  }
  
  
  .cardBody {
    padding: 1rem;
    flex-grow: 1;
  }
  
  .cardTitle {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: white;
  }
  
  .cardPeriod {
    font-size: 0.875rem;
    color: #000000;
    margin-bottom: 0.5rem;
  }
  
  .cardDescription {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: white;
  }
  
  .cardTechnologies {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 15px;
  }
  

  @media (max-width: 1200px) {
    .card2 {
      flex: 0 0 calc(33.333% - 1rem);
    }
  }
  
  @media (max-width: 992px) {
    .card2 {
      flex: 0 0 calc(50% - 1rem);
    }
  }
  
  @media (max-width: 768px) {
    .card2 {
      flex: 0 0 100%;
    }
  }


  .card2:hover {
    opacity: 1;
    animation: zoomIn 0.5s forwards;
  }

  @keyframes zoomIn {
    from {
      transform: translateY(0);

    }
    to {
      transform: translateY(-10px);
    }
  }
  .overlayIcon {
    color: white;
    font-size: 1.5rem;
  } 