.hover_animation {
    position: relative;
    font-size: 3rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 80px;
}

.normalText {
    color: transparent;
    -webkit-text-stroke: 1px rgba(255,255,255,0.6);
}

.h1_element {
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 8px;
    text-transform: uppercase;
    text-decoration: none;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    text-align: center;
    position: relative;
    display: flow-root;
  }
  
  .h1_element::before,
  .h1_element::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    transition: 0.3s ease-out;
  }



  .h1_element:hover::before {
    color: black;
    text-shadow: rgba(255,255,255,0.6);
    transform: translateX(-48%) translateY(-20%) !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    filter: drop-shadow(0 0 40px #1af7ff);
  }
  .h1_element:hover::after {
    color: white;
    transform: translateX(-48%) translateY(-22%) !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    filter: drop-shadow(0 0 40px #1af7ff);
  }

  .container_height {
    height: 100vh;
  }

  .hero_image {
    position:relative;
    right:-12px;
    width:100% !important;
  }

  .mobileMargin {
    margin-top: 5% !important;
  }

  @media (max-width:1068px) {
    .mobileMargin {
      margin-top: 25% !important;
    }
  }

  @media (max-width: 1068px) {
    .mobileTop {
      margin-top: 0% !important;
    }
  }

  
.scrollMore {
  width: 150px;
  border: none;
}

@media (max-width: 1068px) {
  .scrollMore {
    position: absolute !important;
    text-decoration: none !important;
    color: white !important;
  }
}

@media (min-width: 1068px) {
  .scrollMore {
    color: #fff !important;
    position: absolute !important;
    right: 50% !important;
    text-decoration: none !important;
    transform: translateY(-126px);
  }
}

  .scrollMore2 {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: none;
  }

  @media (max-width: 1068px) {
    .scrollMore2 {
      position: relative;
      left: 47.5% !important;
    }
  }

  @media (min-width: 1068px) {
    .scrollMore2 {
      right: 0 !important;
      top: 50% !important;
      position: absolute !important;
    }
  }

  .bounceArrow {
    animation: bounce 1s infinite;
    position: absolute;
    bottom: -10% !important;
    left: 0%;
    transform: translateX(-50%);
}

.bounceArrow2 {
    animation: bounce 1s infinite;
    position: absolute;
    bottom: -100% !important;
    right: 43%;
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-10px);
  }
  60% {
      transform: translateY(-5px);
  }
}

@media (min-width:1068px) {
  .scrollMoreDesktop {
    display: flex;
    position: absolute;
    bottom: 0;
    transform: translate(4%, 40px) !important;
  }
  .scrollMoreMobile {
    display:none !important;
  }
}

@media (max-width:1068px) {
  .scrollMoreMobile {
    display: flex !important;
    flex-basis: content;
    position: absolute;
    bottom: 20px;
}
  .scrollMoreDesktop {
    display:none !important;
  }
}

.setMargin {
  transform: translateX(-50%) !important;
}

